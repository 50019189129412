/* eslint-disable vue/require-v-for-key */
<template>
  <!--底部-->
  <div class="footer">
    <div class="com-container content">
      <ul class="contact_list">
        <li v-if="$store.state.human != 1">
          <i class="el-icon-message"></i>
          <span>联系邮箱</span>service@campcenter.cn
        </li>
        <!-- <li>
          <i class="el-icon-phone-outline"></i>
          <span>联系电话</span>010-56218127
        </li> -->
        <li>
          <i class="el-icon-aim"></i>
          <span>工作时间</span>周一至周五 09:00 - 12:00，14:00 - 18:00
        </li>
      </ul>
      <div class="line"></div>
      <div class="copyRight">
        <div>
          <a href="">Copyright © 2023 营地系统 版权所有</a>
          <a href="https://beian.miit.gov.cn" target="_blank">京ICP备20001854号-2</a>
          <!-- <a target="_blank" href="https://beian.miit.gov.cn"
          >京ICP备19058825号-3</a>
          <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034612"
        >京公网安备 11010802034612号</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "a/scss/common";
.footer {
  margin-top: 20px;
  font-family: PingFang SC;
  font-size: 14px;
  height: 169px;
  background: #000000;
  opacity: 0.9;
  line-height: 60px;
  border-top: 1px solid #ccc;

  .content {
    .contact_list {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 88px;
      margin: 0;
      padding: 0;
      li {
        font-size: 12px;
        height: 14px;
        line-height: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        list-style: none;
        margin-right: 40px;
        span {
          margin: 0 15px 0 7px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
    }
    .copyRight {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 62px;
      margin: 0;
      padding: 0;
      a {
        font-size: 12px;
        font-weight: 400;
        margin-right: 20px;
        color: #FFFFFF;
        text-decoration: none;
        img {
          width: 15px;
          margin-top: -3px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

<template>
  <div class="certificate">
    <div class="title">
      <div v-if="$store.state.human == 1" class="homework_titile">课程证书</div>
      <div v-else class="homework_titile">营地证书</div>
    </div>
    <!-- <div class="content_empty"> -->
    <!-- <div>
        <img src="@/assets/img/homework/homework.png" alt="" />
      </div>

      <p>同学你好，老师暂未发布作业</p>
      <p>请于2021年XX年XX月XX日 前往网站查看作业</p> -->
    <!-- </div> -->
    <div class="content">
      <div class="top">
        <div>
          <p v-if="$store.state.human == 1" class="p_title">
            &emsp;&emsp;
            恭喜你顺利完成所有课程，点击【填写/修改收货地址】以便确认收货地址，证书将以纸质形式邮寄。
          </p>
          <p v-else class="p_title">
            &emsp;&emsp;
            恭喜你顺利完成所有营地课程，点击【填写/修改收货地址】以便确认收货地址，证书将以纸质形式邮寄。
          </p>
        </div>

        <div>
          <div v-if="$store.state.human == 1">
            <img
              src="@/assets/img/certificate_left.jpg"
              alt=""
              style="width: 25%"
            />
            <img
              src="@/assets/img/certificate_right.jpg"
              alt=""
              style="width: 25%"
            />
          </div>
          <div v-else>
            <img src="@/assets/img/certificate.jpg" alt="" style="width: 25%" />
          </div>
        </div>

        <div class="btn">
          <el-button @click="getCertifi">填写/修改收货地址</el-button>
        </div>
      </div>
      <!-- 
      <div class="pay_bottom">
        <div>注意事项：</div>
        <div>
          1、学生支付完成后请不要立即关闭浏览器，等待支付完成返回本页面，以确认支付完成。
        </div>
        <div>
          2、支付确认后，将不能再修改姓名和身份证号，请在支付前核对信息。
        </div>
      </div> -->
    </div>
    <!-- 收货地址 -->
    <delivery-address
      :dialogVisibleAddress="dialogVisibleAddress"
      @closeAddressDialog="closeAddressDialog"
    >
    </delivery-address>
  </div>
</template>

<script>
/* eslint-disable */
import deliveryAddress from "@/components/index/SignUp/deliveryAddress.vue";

export default {
  name: "certificate",
  components: {
    deliveryAddress,
  },
  data() {
    return {
      dialogVisibleAddress: false,
    };
  },
  created() {},
  watch: {},
  methods: {
    getCertifi() {
      this.dialogVisibleAddress = true;
    },
    // 子组件触发，关闭确认报名弹框
    closeAddressDialog(val) {
      console.log(val, "val");
      this.dialogVisibleAddress = val;
    },
  },
};
</script>


<style lang="scss" >
@import "a/scss/btn";

.btn {
  padding-bottom: 0 !important;
  padding-top: 20px !important;
  .el-button {
    width: 160px !important;
  }
}
.flex {
  display: flex;
}

.certificate {
  // background: #ffffff;
  // width: 856px;

  .title {
    padding: 50px 50px 0 50px;
    .homework_titile {
      font-size: 24px;
      font-weight: 500;
      color: #1b1419;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  // 空状态
  // .content_empty {
  // padding-bottom: 200px;
  // p,
  // div {
  //   text-align: center;
  // }
  // }

  // 有证书时候

  .content {
    padding: 0 30px 80px 30px;
    .top {
      padding-bottom: 80px;
      .p_title {
        font-size: 18px;
        font-weight: 500;
        color: #12141c;
        line-height: 28px;
        margin: 10px 0 30px 0;
      }
      div {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  //  注意事项
  .pay_bottom {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    line-height: 21px;
  }

  .el-form-item {
    margin-bottom: 20px;

    // item的验证红点点
    .el-form-item__label {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #12141c;
    }
    &.no_label .el-form-item__label {
      width: 0 !important;
    }
    &.no_label .el-form-item__label::before {
      content: "" !important;
    }

    &.no_label .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>

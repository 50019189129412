<template>
  <el-container>
    <el-header height="104px">
      <Header :typeFu="3"></Header>
      <div class="bg-top"></div>
    </el-header>
    <el-container>
      <div class="com-container index-container">
        <template>
          <div class="camp_left" ref="leftBoxFu" v-if="$store.state.human == 1">
            <ul
              :class="{
                'is-fixed': isPosition == 1,
                'is-absolute': isPosition == 2,
              }"
              class="camp_left_box"
              ref="leftBox"
            >
              <li
                :class="{ active: campindex_type === index }"
                :key="index"
                @click="ToSignUp(index)"
                v-for="(item, index) in qinghuaTabs"
              >
                <img :src="item.url"/>
                {{ item.desc }}
              </li>
            </ul>
          </div>
          <div class="camp_left" ref="leftBoxFu" v-else>
            <ul
              :class="{
                'is-fixed': isPosition == 1,
                'is-absolute': isPosition == 2,
              }"
              class="camp_left_box"
              ref="leftBox"
            >
              <li
                :class="{ active: campindex_type === index }"
                :key="index"
                @click="ToSignUp(index)"
                v-for="(item, index) in tabs"
              >
                <img :src="item.url"/>
                {{ item.desc }}
              </li>
            </ul>
          </div>
        </template>

        <div class="camp_right" ref="rightBox">
          <div @click="backArrow()" class="back-arrow">
            <i class="el-icon-arrow-left"/>
            {{$store.state.info.name}}
          </div>

          <SignUp @getStatus="getStatus" v-if="this.campindex_type == 0"></SignUp>
          <Homework v-if="this.campindex_type == 1"></Homework>
          <Certificate v-if="this.campindex_type == 2"></Certificate>
          <!-- <homeRefer v-if="this.homework_val == 3"> </homeRefer> -->
        </div>
      </div>
      <Footer></Footer>
    </el-container>
  </el-container>
</template>

<script>
  /* eslint-disable */

  import Header from "@/components/Header.vue";
  import Footer from "@/components/index/Footer.vue";
  import svg1 from "@/assets/img/signUp/icon.svg";
  import svg2 from "@/assets/img/signUp/icon-1.svg";
  import svg3 from "@/assets/img/signUp/icon-2.svg";
  import SignUp from "v/index/camp/SignUp";
  import Homework from "v/index/camp/Homework";
  import Certificate from "v/index/camp/Certificate";
  // import homeRefer from "v/index/camp/homework/refer";

  export default {
    name: "campIndex",
    components: {
      Header,
      Footer,
      SignUp,
      Homework,
      Certificate,
      // homeRefer,
    },
    // props:{}
    data() {
      return {
        index: "",
        isPosition: 0, // 左侧box是否浮动
        pageYOffset: 0, // 左侧box浮动时，滚动的高度
        scorllHeight: 0, // 最大滚动高度
        tabs: [
          {
            id: "0",
            desc: "营地报名",
            icon: "icon-icon1",
            url: svg1,
          },
          {
            id: "1",
            desc: "营地作业",
            icon: "icon-icon-1",
            url: svg2,
          },
          {
            id: "2",
            desc: "营地证书",
            icon: "icon-icon-2",
            url: svg3,
          },
        ],
        qinghuaTabs: [
          {
            id: "0",
            desc: "课程报名",
            icon: "icon-icon1",
            url: svg1,
          },
          {
            id: "1",
            desc: "课程作业",
            icon: "icon-icon-1",
            url: svg2,
          },
          {
            id: "2",
            desc: "课程证书",
            icon: "icon-icon-2",
            url: svg3,
          },
        ],
        campindex_type: 0,
        index_status: "",
        // homework_val: "",
      };
    },
    watch: {},
    created() {
      // this.getStatus();
      // this.torefer();
    },
    mounted() {
      window.addEventListener("scroll", this.handleScrollX, true);
      window.addEventListener("resize", this.onResize, true);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScrollX, true);
      window.removeEventListener("resize", this.onResize, true);
    },
    methods: {
      // 箭头返回按钮
      backArrow() {
        let root = document.querySelector(":root");
        root.style.setProperty("--color", "#4FACFE");
        this.$router.push({
          path: "/myInfo/signUpList",
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // torefer(val) {
      //   console.log(val, "val");
      //   this.homework_val = val;
      // },
      getStatus(val) {
        this.index_status = val;
        this.ToSignUp(this.campindex_type);
      },
      ToSignUp(index) {
        let code = this.$store.state.indexIdentity;
        this.campindex_type = index;
        if (this.campindex_type == 0) {
          this.$router.push({
            path: "/signUp/" + this.index_status,
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        } else if (this.campindex_type == 1) {
          this.$router.push({
            path: "/homework",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        } else if (this.campindex_type == 2) {
          this.$router.push({
            path: "/certificate",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        }
      },
      onResize() {
        const refLeft = this.$refs["leftBox"];
        const leftBoxFu = this.$refs["leftBoxFu"];
        refLeft.style.width = leftBoxFu.offsetWidth + "px";
      },
      // 页面滚动事件
      handleScrollX() {
        const top = document.documentElement.scrollTop || document.body.scrollTop;
        const refLeft = this.$refs["leftBox"];
        const leftBoxFu = this.$refs["leftBoxFu"];
        const refRight = this.$refs["rightBox"];
        if (top > 0) {
          if (this.isPosition == 0) {
            this.scorllHeight = refRight.offsetHeight - refLeft.offsetHeight;
            refLeft.style.width = leftBoxFu.offsetWidth + "px";
          }
          this.isPosition = 1;
        } else {
          if ((this.isPosition = 1)) {
            this.isPosition = 0;
          }
        }
        if (top >= this.scorllHeight) {
          if (this.isPosition == 1) {
            this.isPosition = 2;
          }
        } else if (top < this.scorllHeight) {
          if (this.isPosition == 2) {
            this.isPosition = 1;
            refLeft.style.width = leftBoxFu.offsetWidth + "px";
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/common";
  @import "a/scss/index";
  @import "a/style";

  @font-face {
    font-family: "icomoon";
    src: url("../../../assets/fonts/icomoon.eot?dcunb6");
    src: url("../../../assets/fonts/icomoon.eot?dcunb6#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/icomoon.ttf?dcunb6") format("truetype"),
    url("../../../assets/fonts/icomoon.woff?dcunb6") format("woff"),
    url("../../../assets/fonts/icomoon.svg?dcunb6#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .camp_left i {
    font-family: "icomoon";
  }

  body {
    background-color: #f8f8f8;
  }

  .el-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    min-width: 1150px;
    background-color: #f8f8f8;

    .el-header {
      position: fixed;
      width: 100%;
      padding: 0;
      background-color: #ffffff;
      z-index: 2000; //消息提示框z-index=2019

      .bg-top {
        height: 24px;
        background-color: #f8f8f8;
      }
    }

    .el-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin-top: 104px;

      .index-container {
        padding: 0 0 50px 0;
        display: flex;
        flex-flow: row;
        flex: 1;

        .camp_left {
          position: relative;
          width: 22%;
          max-width: 280px;
          min-width: 200px;
          height: 300px;

          background-color: #ffffff;
          border-radius: 8px;
          padding: 20px 0;
          margin-right: 25px;

          .camp_left_box {
            padding-inline-start: 0 !important;
          }

          &.is-fixed {
            position: fixed;
            top: 104px;
            /*width: 14.4%;*/
            max-width: 280px;
            min-width: 216px;
          }

          &.is-absolute {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }

        .camp_right {
          position: relative;
          flex: 1;
          width: calc(100% - 100px);
          border-radius: 8px;
          background-color: #ffffff;

          .back-arrow {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #666666;
            font-size: 14px;
            line-height: 32px;

            &:hover {
              color: var(--color);
              cursor: pointer;
            }

            .el-icon-arrow-left {
              vertical-align: middle;
              font-size: 18px;
            }

          }
        }


        li {
          position: relative;
          padding: 0 23px 0 51px;
          height: 44px;
          line-height: 44px;
          margin: 10px 0;
          font-size: 15px;
          list-style: none;
          border-left: 4px solid transparent;

          img {
            vertical-align: middle;
            margin: -3px 8px 0 0;
            width: 20px;
            position: absolute;
            left: -1000px;
            filter: drop-shadow(var(--color) 1023px 13px) !important;
          }

          &.active,
          &:hover {
            border-left: 4px solid var(--color);
            cursor: pointer;
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
</style>
